import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { claimRedeem } from "../../ApiCalls/Redeem/api_v1_redeem";
import coinsBG from '../../images/v1/redeem/bg_redeem.png';

const RedeemForm = (props) => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState(props.showForm.tel);
    const [email, setEmail] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        document.body.style.backgroundColor = "#1A1A1A";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#1A1A1A");
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError("");

        const claimData = {
            item: props.showForm.item,
            uuid: props.showForm.uuid,
            phone: mobile,
            email: email,
            name: name
        };

        try {
            const response = await claimRedeem(claimData);
            if (response.data.message === "ok" && response.status === 200) {
                navigate("/thankyou");
            } else if (response.data.message === "Ko" && response.status === 200) {
                setError("Insufficient Coins");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            setError("An error occurred. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="redeem-page">
            <div className="redeem-form-container">
                <h1 className="congratulations-text">Congratulations!</h1>
                <p className="redeem-info">You can redeem your DING coins to purchase</p>
                <p className="reward-name">{props.showForm.key}</p>

                <form onSubmit={handleSubmit} className="redeem-form">
                    <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                            id="name"
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                            minLength="3"
                            maxLength="50"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mobile">Mobile number</label>
                        <input
                            id="mobile"
                            type="tel"
                            value={mobile}
                            onChange={(e) => setMobile(e.target.value)}
                            required
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            minLength="14"
                            maxLength="50"
                        />
                    </div>
                    <button type="submit" className="redeem-button" disabled={isSubmitting}>
                        {isSubmitting ? 'Redeeming...' : 'Redeem'}
                    </button>
                </form>

                {error && <p className="error-message">{error}</p>}
            </div>

            <style jsx>{`
                .redeem-page {
                    background-image: url(${coinsBG});
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    min-height: 100vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                }

                .redeem-form-container {
                    background-color: rgba(26, 26, 26, 0.8);
                    color: white;
                    padding: 30px;
                    border-radius: 10px;
                    max-width: 400px;
                    width: 100%;
                }

                .congratulations-text {
                    color: #FFD700;
                    font-size: 24px;
                    margin-bottom: 10px;
                    text-align: center;
                }

                .redeem-info, .reward-name {
                    text-align: center;
                    margin-bottom: 5px;
                }

                .reward-name {
                    font-size: 20px;
                    font-weight: bold;
                    color: #FFD700;
                }

                .redeem-form {
                    margin-top: 20px;
                }

                .form-group {
                    margin-bottom: 15px;
                }

                label {
                    display: block;
                    margin-bottom: 5px;
                    color: #FFD700;
                }

                input {
                    width: 100%;
                    padding: 10px;
                    background-color: rgba(255, 255, 255, 0.1);
                    border: 1px solid rgba(255, 255, 255, 0.3);
                    border-radius: 5px;
                    color: white;
                }

                .redeem-button {
                    width: 100%;
                    padding: 10px;
                    background-color: #FFD700;
                    color: #1A1A1A;
                    border: none;
                    border-radius: 5px;
                    font-weight: bold;
                    cursor: pointer;
                    transition: background-color 0.3s;
                }

                .redeem-button:disabled {
                    background-color: #cccccc;
                    cursor: not-allowed;
                }

                .error-message {
                    color: #ff6b6b;
                    text-align: center;
                    margin-top: 10px;
                }
            `}</style>
        </div>
    );
};

export default RedeemForm;