import React, { useState, useEffect, useCallback } from 'react';
import spinWheelImg from '../../images/v1/rewards/spinWheel.png';
import './SpinWheel.css';
import { getSpinStatus, doSpin, doSpinAgain, redeemSpin, getUserCoins } from '../../ApiCalls/Rewards/api_v1_rewards';
import RewardPopup from './RewardPopup';
import SpinRedeemDialog from './SpinRedeemDialog';
import { useCoinBalance } from '../header/CoinContext';

const CoinSVG = ({ className }) => (
    <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill="#FFA500" />
        <circle cx="20" cy="20" r="16" fill="#FFD700" />
        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fill="#FFA500" fontSize="16" fontWeight="bold">$</text>
    </svg>
);

const SpinWheel = () => {
    const { updateCoinBalance } = useCoinBalance();
    const [showRedeemDialog, setShowRedeemDialog] = useState(false);
    const [showRewardPopup, setShowRewardPopup] = useState(false);
    const [currentReward, setCurrentReward] = useState(0);
    const [currentWheelPosition, setCurrentWheelPosition] = useState(0);
    const [spinStatus, setSpinStatus] = useState({
        spinAvailedToday: false,
        canSpinAgain: false,
        pendingSpinCount: 0,
        spinAgainId: '',
        redeemSpinStatus: false
    });
    const [isSpinning, setIsSpinning] = useState(false);
    const [rotation, setRotation] = useState(0);
    const [timeRemaining, setTimeRemaining] = useState('');
    const [userCoins, setUserCoins] = useState(0);
    const spinAmount = 100;

    const fetchSpinStatus = useCallback(async () => {
        try {
            const response = await getSpinStatus();
            const data = response.data.data;
            const updatedSpinStatus = {
                spinAvailedToday: data.today.spin_availed_today,
                canSpinAgain: data.today.can_spin_again,
                pendingSpinCount: data.pending_spin_count.count,
                spinAgainId: data.pending_spin_ids?.[0]?.spin_id || data.today.spin_again_id || '',
                redeemSpinStatus: !data.today.can_spin_again && data.pending_spin_count.count === 0
            };

            setSpinStatus(updatedSpinStatus);
            console.log(response.data.data);

            setSpinStatus(
                {
                    spinAvailedToday: updatedSpinStatus.spinAvailedToday,
                    canSpinAgain: updatedSpinStatus.canSpinAgain,
                    pendingSpinCount: updatedSpinStatus.pendingSpinCount,
                    spinAgainId: updatedSpinStatus.spinAgainId,
                    redeemSpinStatus: !updatedSpinStatus.spinAvailedToday ? false : updatedSpinStatus.canSpinAgain ? false : updatedSpinStatus.pendingSpinCount !== 0 ? false : true
                }
            )
            console.log(spinStatus);

        } catch (error) {
            console.error('Error fetching spin status:', error);
        }
    }, []);



    const fetchUserCoins = useCallback(async () => {
        try {
            const response = await getUserCoins();
            setUserCoins(response.data.coins);
        } catch (error) {
            console.error('Error fetching user coins:', error);
        }
    }, []);

    const updateTimeRemaining = useCallback(() => {
        const now = new Date();
        const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
        const diff = midnight - now;
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);
        setTimeRemaining(`${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`);
    }, []);

    useEffect(() => {
        document.title = "Spin Wheel | Ding";
    }, [])

    useEffect(() => {
        fetchSpinStatus();
        updateTimeRemaining();
        updateCoinBalance();
        fetchUserCoins();
        const timer = setInterval(updateTimeRemaining, 1000);
        return () => clearInterval(timer);
    }, [fetchSpinStatus, updateTimeRemaining, fetchUserCoins]);

    const getRandomDegreeBasedOnProbability = () => {
        const segments = [10, 324, 288, 252, 216, 180, 144, 108, 72, 36];
        const probabilities = [0.25, 0.15, 0.10, 0.1, 0.1, 0.1, 0.03, 0.02, 0.02, 0.02];
        let randomValue = Math.random();
        let cumulativeProbability = 0;
        for (let i = 0; i < segments.length; i++) {
            cumulativeProbability += probabilities[i];
            if (randomValue <= cumulativeProbability) {
                return segments[i];
            }
        }
        return segments[segments.length - 1];
    };

    const getReward = (degree) => {
        const rewards = {
            324: 25, 288: 50, 252: 75, 216: 100, 180: 125,
            144: 150, 108: 250, 72: 350, 36: 500, 0: 10
        };
        return rewards[degree] || 10;
    };

    const spinWheel = async () => {
        if (spinStatus.redeemSpinStatus) {
            setShowRedeemDialog(true);
            return;
        }

        const degree = getRandomDegreeBasedOnProbability();

        const minFullSpins = 8;
        const maxFullSpins = 13;
        const fullSpins = Math.floor(Math.random() * (maxFullSpins - minFullSpins + 1)) + minFullSpins;

        // Calculate the total rotation needed to reach the desired position
        const targetPosition = degree;
        const currentPosition = currentWheelPosition % 360;
        let totalRotation = (360 * fullSpins) + (targetPosition - currentPosition + 360) % 360;

        // Ensure the wheel always spins at least one full rotation
        if (totalRotation < 360) {
            totalRotation += 360;
        }

        setIsSpinning(true);
        setRotation(prevRotation => prevRotation + totalRotation);

        // Update the current wheel position
        setCurrentWheelPosition(prevPosition => (prevPosition + totalRotation) % 360);

        try {
            let response;
            const reward = getReward(degree);

            if (!spinStatus.spinAvailedToday) {
                response = await doSpin(reward);
            } else if (spinStatus.spinAgainId) {
                response = await doSpinAgain(reward, spinStatus.spinAgainId);
            }

            if (response && response.data.message !== 'Ko') {
                setTimeout(() => {
                    setIsSpinning(false);
                    showRewardDialog(reward);
                    fetchUserCoins();
                    fetchSpinStatus();
                    updateCoinBalance();
                }, 4500);
            }
        } catch (error) {
            console.error('Error spinning wheel:', error);
            setIsSpinning(false);
        }
    };


    const redeemSpinHandler = async () => {
        try {
            const response = await redeemSpin(spinAmount);
            if (response.data.message !== 'Ko') {
                const { spin_redeemed, can_spin_again } = response.data.data;
                if (spin_redeemed && can_spin_again) {
                    setSpinStatus(prev => ({ ...prev, redeemSpinStatus: false }));
                    fetchUserCoins();
                    fetchSpinStatus();
                    updateCoinBalance();
                    // spinWheel();
                }
            }
        } catch (error) {
            console.error('Error redeeming spin:', error);
        }
    };

    const showRewardDialog = (reward) => {
        setCurrentReward(reward);
        setShowRewardPopup(true);
    };

    const closeRewardPopup = () => {
        setShowRewardPopup(false);
    };

    const closeRedeemDialog = () => {
        setShowRedeemDialog(false);
    };

    const handleRedeemSpin = () => {
        redeemSpinHandler();
        setShowRedeemDialog(false);
    };

    return (
        <div className="spin-wheel-page">
            <div className="blur blur-1"></div>
            <div className="blur blur-2"></div>
            <div className="blur blur-3"></div>
            <div className="blur blur-4"></div>
            <div className="blur blur-5"></div>
            <div className="blur blur-6"></div>
            <div className="blur blur-7"></div>
            {showRewardPopup && (
                <RewardPopup reward={currentReward} onClose={closeRewardPopup} />
            )}
            {showRedeemDialog && (
                <SpinRedeemDialog
                    spinAmount={spinAmount}
                    timeRemaining={timeRemaining}
                    onClose={closeRedeemDialog}
                    onRedeem={handleRedeemSpin}
                />
            )}
            <div className="centered">
                <h1 className='stroke-color-black'>Spin & Win</h1>
                <p className='stroke-color-black'>Win up to 500 coins by spinning the wheel</p>

                <div className="wheel-container">
                    <img
                        src={spinWheelImg}
                        alt="Spin Wheel"
                        className="wheel"
                        style={{ transform: `rotate(${rotation}deg)` }}
                    />
                    <div className="pointer"></div>
                </div>

                <button
                    className="spin-button"
                    onClick={spinWheel}
                    disabled={isSpinning}
                >
                    {isSpinning ? 'SPINNING...' : 'SPIN'}
                </button>

                <CoinSVG className="coin coin-1" />
                <CoinSVG className="coin coin-2" />
                <CoinSVG className="coin coin-3" />
            </div>
        </div>
    );
};

export default SpinWheel;