const Mouth = ({ mouthType = "Concerned" }) => {
    const mouthMap = {
        "Concerned": (`
            <g id="Mouth/Concerned" transform="translate(2.000000, 52.000000)">
                <defs>
                    <path
                        d="M35.117844,15.1280772 C36.1757121,24.6198025 44.2259873,32 54,32 C63.8042055,32 71.8740075,24.574136 72.8917593,15.0400546 C72.9736685,14.272746 72.1167429,13 71.042767,13 C56.1487536,13 44.7379213,13 37.0868244,13 C36.0066168,13 35.0120058,14.1784435 35.117844,15.1280772 Z"
                        id="path-11322"
                    />
                </defs>
                <mask id="mask-11323" fill="white">
                    <use
                        xlinkHref="#path-11322"
                        transform="translate(54.003637, 22.500000) scale(1, -1) translate(-54.003637, -22.500000)"
                    />
                </mask>
                <use
                    id="Mouth"
                    fillOpacity="0.699999988"
                    fill="#000000"
                    fillRule="evenodd"
                    transform="translate(54.003637, 22.500000) scale(1, -1) translate(-54.003637, -22.500000)"
                    xlinkHref="#path-11322"
                />
                <rect
                    id="Teeth"
                    fill="#FFFFFF"
                    fillRule="evenodd"
                    mask="url(#mask-11323)"
                    x="39"
                    y="2"
                    width="31"
                    height="16"
                    rx="5"
                />
                <g
                    id="Tongue"
                    strokeWidth="1"
                    fillRule="evenodd"
                    mask="url(#mask-11323)"
                    fill="#FF4F6D"
                >
                    <g transform="translate(38.000000, 24.000000)">
                        <circle id="friend?" cx="11" cy="11" r="11" />
                        <circle id="How-you-doing" cx="21" cy="11" r="11" />
                    </g>
                </g>
            </g>
        `),
        "Default": (`
            <g id="Mouth/Default" transform="translate(2.000000, 52.000000)" fill-opacity="0.699999988">
                <path d="M40,15 C40,22.7319865 46.2680135,29 54,29 L54,29 C61.7319865,29 68,22.7319865 68,15" id="Mouth"></path>
            </g>
        `),
        "Disbelief": (`
            <g id="Mouth/Disbelief" transform="translate(2.000000, 52.000000)" fill-opacity="0.699999988" fill="#000000">
                <path d="M40,15 C40,22.7319865 46.2680135,29 54,29 L54,29 C61.7319865,29 68,22.7319865 68,15" id="Mouth" transform="translate(54.000000, 22.000000) scale(1, -1) translate(-54.000000, -22.000000) "></path>
            </g>
        `),
        "Eating": (`
            <g id="Mouth/Eating" transform="translate(2.000000, 52.000000)">
                <g id="Om-Nom-Nom" opacity="0.599999964" stroke-width="1" transform="translate(28.000000, 6.000000)" fill-opacity="0.599999964" fill="#000000">
                    <path d="M16.1906378,10.106319 C16.0179484,4.99553347 11.7923466,0.797193688 6.29352385,0 C9.66004124,1.95870633 11.9804619,5.49520667 11.9804619,9.67694348 C11.9804619,15.344608 6.50694731,20.2451296 0.176591694,20.2451296 C0.11761218,20.2451296 0.0587475828,20.2447983 0,20.244138 L8.8963743e-11,20.244138 C1.35764479,20.7317259 2.83995964,21 4.39225962,21 C9.71395931,21 14.2131224,17.8469699 15.6863572,13.5136402 C18.1609431,15.6698775 21.8629994,17.0394229 26,17.0394229 C30.1370006,17.0394229 33.8390569,15.6698775 36.3136428,13.5136402 C37.7868776,17.8469699 42.2860407,21 47.6077404,21 C49.1600404,21 50.6423552,20.7317259 52,20.244138 L52,20.244138 C51.9412524,20.2447983 51.8823878,20.2451296 51.8234083,20.2451296 C45.4930527,20.2451296 40.0195381,15.344608 40.0195381,9.67694348 C40.0195381,5.49520667 42.3399588,1.95870633 45.7064761,0 C40.2076534,0.797193688 35.9820516,4.99553347 35.8093622,10.106319 C33.2452605,11.8422828 29.7948543,12.9056086 26,12.9056086 C22.2051457,12.9056086 18.7547395,11.8422828 16.1906378,10.106319 Z" id="Delicious"></path>
                </g>
                <circle id="Redish" fill-opacity="0.2" fill="#FF4646" cx="17" cy="15" r="9"></circle>
                <circle id="Redish" fill-opacity="0.2" fill="#FF4646" cx="91" cy="15" r="9"></circle>
            </g>
        `),
        "Grimace": (`
            <g id="Mouth/Grimace" transform="translate(2.000000, 56.000000)">
                <defs>
                    <rect id="react-path-59742" x="24" y="9" width="60" height="22" rx="11"></rect>
                </defs>
                <rect id="Mouth" fill-opacity="0.599999964" fill="#000000" fill-rule="evenodd" x="22" y="7" width="64" height="26" rx="13"></rect>
                <mask id="react-mask-59743" fill="white">
                    <use xlinkHref="#react-path-59742"></use>
                </mask>
                <use id="Mouth" fill="#FFFFFF" fill-rule="evenodd" xlinkHref="#react-path-59742"></use>
                <path d="M71,22 L62,22 L62,34 L58,34 L58,22 L49,22 L49,34 L45,34 L45,22 L36,22 L36,34 L32,34 L32,22 L24,22 L24,18 L32,18 L32,6 L36,6 L36,18 L45,18 L45,6 L49,6 L49,18 L58,18 L58,6 L62,6 L62,18 L71,18 L71,6 L75,6 L75,18 L83.8666667,18 L83.8666667,22 L75,22 L75,34 L71,34 L71,22 Z" id="Grimace-Teeth" fill="#E6E6E6" fill-rule="evenodd" mask="url(#react-mask-59743)"></path>
            </g>

        `),
        "ScreamOpen": (`
            <g id="Mouth/Scream-Open" transform="translate(2.000000, 52.000000)">
                <defs>
                    <path d="M34.0082051,15.1361102 C35.1280248,29.123916 38.2345159,40.9925405 53.9961505,40.9999965 C69.757785,41.0074525 72.9169073,29.0566179 73.9942614,15.0063928 C74.0809675,13.8756222 73.1738581,12.9999965 72.0369872,12.9999965 C65.3505138,12.9999965 62.6703194,14.9936002 53.9894323,14.9999965 C45.3085452,15.0063928 40.7567994,12.9999965 36.0924943,12.9999965 C34.9490269,12.9999965 33.8961688,13.7366502 34.0082051,15.1361102 Z" id="react-path-15062"></path>
                </defs>
                <mask id="react-mask-15063" fill="white">
                    <use xlinkHref="#react-path-15062" transform="translate(54.000000, 26.999998) scale(1, -1) translate(-54.000000, -26.999998) "></use>
                </mask>
                <use id="Mouth" fill-opacity="0.699999988" fill="#000000" fill-rule="evenodd" transform="translate(54.000000, 26.999998) scale(1, -1) translate(-54.000000, -26.999998) " xlinkHref="#react-path-15062"></use>
                <rect id="Teeth" fill="#FFFFFF" fill-rule="evenodd" mask="url(#react-mask-15063)" x="39" y="2" width="31" height="16" rx="5"></rect>
                <g id="Tongue" stroke-width="1" fill-rule="evenodd" mask="url(#react-mask-15063)" fill="#FF4F6D">
                    <g transform="translate(38.000000, 32.000000)" id="Say-ahhhh">
                        <circle cx="11" cy="11" r="11"></circle>
                        <circle cx="21" cy="11" r="11"></circle>
                    </g>
                </g>
            </g>
        `),
        "Sad": (`
            <g
                id="Mouth/Sad"
                transform="translate(2.000000, 52.000000)"
                fill-opacity="0.699999988"
                fill="#000000">
                <path
                    d="M40.0582943,16.6539438 C40.7076459,23.6831146 46.7016363,28.3768187 54,28.3768187 C61.3416045,28.3768187 67.3633339,23.627332 67.9526838,16.5287605 C67.9840218,16.1513016 67.0772329,15.8529531 66.6289111,16.077395 C61.0902255,18.8502083 56.8805885,20.2366149 54,20.2366149 C51.1558456,20.2366149 47.0072148,18.8804569 41.5541074,16.168141 C41.0473376,15.9160792 40.0197139,16.2363147 40.0582943,16.6539438 Z"
                    id="Mouth"
                    transform="translate(54.005357, 22.188409) scale(1, -1) translate(-54.005357, -22.188409) "
                />
            </g>
        `),
        "Serious": (`
            <g id="Mouth/Serious" transform="translate(2.000000, 52.000000)" fill="#000000" fill-opacity="0.699999988">
                <rect id="Why-so-serious?" x="42" y="18" width="24" height="6" rx="3"></rect>
            </g>
        `),
        "Smile": (`
            <g id="Mouth/Smile" transform="translate(2.000000, 52.000000)">
                <defs>
                    <path d="M35.117844,15.1280772 C36.1757121,24.6198025 44.2259873,32 54,32 C63.8042055,32 71.8740075,24.574136 72.8917593,15.0400546 C72.9736685,14.272746 72.1167429,13 71.042767,13 C56.1487536,13 44.7379213,13 37.0868244,13 C36.0066168,13 35.0120058,14.1784435 35.117844,15.1280772 Z" id="react-path-17111"></path>
                </defs>
                <mask id="react-mask-17112" fill="white">
                    <use xlinkHref="#react-path-17111"></use>
                </mask>
                <use id="Mouth" fill-opacity="0.699999988" fill="#000000" fill-rule="evenodd" xlinkHref="#react-path-17111"></use>
                <rect id="Teeth" fill="#FFFFFF" fill-rule="evenodd" mask="url(#react-mask-17112)" x="39" y="2" width="31" height="16" rx="5"></rect>
                <g id="Tongue" stroke-width="1" fill-rule="evenodd" mask="url(#react-mask-17112)" fill="#FF4F6D">
                    <g transform="translate(38.000000, 24.000000)">
                        <circle cx="11" cy="11" r="11"></circle>
                        <circle cx="21" cy="11" r="11"></circle>
                    </g>
                </g>
            </g>
        `),
        "Tongue": (`
            <g id="Mouth/Tongue" transform="translate(2.000000, 52.000000)">
                <defs>
                    <path d="M29,15.6086957 C30.410031,25.2313711 41.062182,33 54,33 C66.9681454,33 77.6461342,25.183301 79,14.7391304 C79.1012093,14.3397326 78.775269,13 76.826087,13 C56.838426,13 41.7395748,13 31.173913,13 C29.3833142,13 28.870211,14.2404669 29,15.6086957 Z" id="react-path-17809"></path>
                </defs>
                <mask id="react-mask-17810" fill="white">
                    <use xlinkHref="#react-path-17809"></use>
                </mask>
                <use id="Mouth" fill-opacity="0.699999988" fill="#000000" fill-rule="evenodd" xlinkHref="#react-path-17809"></use>
                <rect id="Teeth" fill="#FFFFFF" fill-rule="evenodd" mask="url(#react-mask-17810)" x="39" y="2" width="31" height="16" rx="5"></rect>
                <path d="M65.9841079,23.7466656 C65.9945954,23.8296335 66,23.9141856 66,24 L66,33 C66,39.0751322 61.0751322,44 55,44 L54,44 C47.9248678,44 43,39.0751322 43,33 L43,24 L43,24 C43,23.9141856 43.0054046,23.8296335 43.0158921,23.7466656 C43.0053561,23.6651805 43,23.5829271 43,23.5 C43,21.5670034 45.9101491,20 49.5,20 C51.510438,20 53.3076958,20.4914717 54.5,21.2634601 C55.6923042,20.4914717 57.489562,20 59.5,20 C63.0898509,20 66,21.5670034 66,23.5 C66,23.5829271 65.9946439,23.6651805 65.9841079,23.7466656 Z" id="Tongue" fill="#FF4F6D" fill-rule="evenodd"></path>
            </g>
        `),
        "Twinkle": (`
            <g id="Mouth/Twinkle" transform="translate(2.000000, 52.000000)" fill-opacity="0.599999964" fill-rule="nonzero" fill="#000000">
                <path d="M40,16 C40,21.371763 46.1581544,25 54,25 C61.8418456,25 68,21.371763 68,16 C68,14.8954305 67.050301,14 66,14 C64.7072748,14 64.1302316,14.9051755 64,16 C62.7575758,18.9378973 59.6832595,20.7163149 54,21 C48.3167405,20.7163149 45.2424242,18.9378973 44,16 C43.8697684,14.9051755 43.2927252,14 42,14 C40.949699,14 40,14.8954305 40,16 Z" id="Mouth"></path>
            </g>
        `),
        "Vomit": (`
            <g id="Mouth/Vomit" transform="translate(2.000000, 52.000000)">
                <defs>
                    <path d="M34.0082051,12.6020819 C35.1280248,23.0929366 38.2345159,31.9944054 53.9961505,31.9999974 C69.757785,32.0055894 72.9169073,23.0424631 73.9942614,12.5047938 C74.0809675,11.6567158 73.1738581,10.9999965 72.0369872,10.9999965 C65.3505138,10.9999965 62.6703194,12.4951994 53.9894323,12.4999966 C45.3085452,12.5047938 40.7567994,10.9999965 36.0924943,10.9999965 C34.9490269,10.9999965 33.8961688,11.5524868 34.0082051,12.6020819 Z" id="react-path-106864"></path>
                    <path d="M59.9170416,36 L60,36 C60,39.3137085 62.6862915,42 66,42 C69.3137085,42 72,39.3137085 72,36 L72,35 L72,31 C72,27.6862915 69.3137085,25 66,25 L66,25 L42,25 L42,25 C38.6862915,25 36,27.6862915 36,31 L36,31 L36,35 L36,38 C36,41.3137085 38.6862915,44 42,44 C45.3137085,44 48,41.3137085 48,38 L48,36 L48.0829584,36 C48.5590365,33.1622867 51.0270037,31 54,31 C56.9729963,31 59.4409635,33.1622867 59.9170416,36 Z" id="react-path-106865"></path>
                    <filter x="-1.4%" y="-2.6%" width="102.8%" height="105.3%" filterUnits="objectBoundingBox" id="react-filter-106867">
                        <feOffset dx="0" dy="-1" in="SourceAlpha" result="shadowOffsetInner1"></feOffset>
                        <feComposite in="shadowOffsetInner1" in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowInnerInner1"></feComposite>
                        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" type="matrix" in="shadowInnerInner1"></feColorMatrix>
                    </filter>
                </defs>
                <mask id="react-mask-106866" fill="white">
                    <use xlinkHref="#react-path-106864" transform="translate(54.000000, 21.499998) scale(1, -1) translate(-54.000000, -21.499998) "></use>
                </mask>
                <use id="Mouth" fill-opacity="0.699999988" fill="#000000" fill-rule="evenodd" transform="translate(54.000000, 21.499998) scale(1, -1) translate(-54.000000, -21.499998) " xlinkHref="#react-path-106864"></use>
                <rect id="Teeth" fill="#FFFFFF" fill-rule="evenodd" mask="url(#react-mask-106866)" x="39" y="0" width="31" height="16" rx="5"></rect>
                <g id="Vomit-Stuff">
                    <use fill="green" fill-rule="evenodd" xlinkHref="#react-path-106865"></use>
                    <use fill="green" fill-opacity="1" filter="url(#react-filter-106867)" xlinkHref="#react-path-106865"></use>
                </g>
            </g>`)
    };

    return (
        mouthMap[mouthType]
    );
};

export default Mouth;
