import React from 'react';

import weeklyChallenge from '../../../images/v1/rewards/weeklyChallenge.svg'

const WeeklyChallenge = () => {
  return (
    <div className="weekly-challenge">
      <div className="blur left-blur"></div>
      <div className="blur bottom-blur"></div>
      <div className="blur right-blur"></div>
      <div className="content">
        <div className="text">
          <h2 className='stroke-color-black'>Weekly Challenge</h2>
          <p className='stroke-color-black'>Play the trivia and become winner of the week to get rewarded</p>
        </div>
        <div className="image">
          <img src={weeklyChallenge} alt="Reward box" />
        </div>
      </div>
      <style jsx>{`
        .weekly-challenge {
          background-color: #FBA8FF;
          border-radius: 20px;
          padding: 20px;
          position: relative;
          overflow: hidden;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .blur {
          position: absolute;
          filter: blur(30px);
          opacity: 0.3;
        }

        .left-blur {
          background-color: #5FF5EF;
          width: 100px;
          height: 100%;
          top: 0;
          left: -50px;
        }

        .bottom-blur {
          background-color: #D42F55;
          width: 100%;
          height: 60px;
          bottom: -30px;
          left: 0;
        }

        .right-blur {
          background-color: #812785;
          width: 60px;
          height: 100%;
          top: 0;
          right: -30px;
        }

        .content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          z-index: 1;
        }

        .text {
          flex: 1;
        }

        h2 {
          font-size: 22px;
          font-weight: bold;
          margin: 0 0 10px 0;
          color: #252525;
        }

            .image {
          margin-left: 20px;
        }

        .image img {
          width: 80px;
          height: 80px;
          object-fit: cover;
        }
      `}</style>
    </div>
  );
};

export default WeeklyChallenge;