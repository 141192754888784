import React, { useState, useEffect } from 'react';
import './RewardsNew.css';
import chestIcon from '../../images/v1/rewards/chest.png';
import chestOpen from '../../images/v1/rewards/ic_chest_open.png';
import DailyLogin from './cards/DailyLogin';
import WeeklyChallenge from './cards/WeeklyChallenge';
import MonthlyChallenge from './cards/MonthlyReward';
import ShareAndGet from './cards/ShareAndGet';
import SpinAndWin from './cards/SpinAndWin';
import { getStreaksInfo } from '../../ApiCalls/Rewards/api_v1_rewards';
import fireIcon from '../../images/v1/rewards/streak_icon.png'

const StreakCounterIcon = ({ filled }) => (
    <svg width="12.91" height="19.875" viewBox="0 0 12.91 19.875">
        <defs>
            <linearGradient id="orangeYellowGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#FFA500" />
                <stop offset="100%" stopColor="#FFD700" />
            </linearGradient>
        </defs>
        <path
            d="M8.569,2.315C8.134,0.937 6.856,0 5.41,0L3.315,0C1.076,0 -0.518,2.175 0.156,4.31L4.341,17.56C4.776,18.938 6.055,19.875 7.5,19.875L9.595,19.875C11.834,19.875 13.428,17.7 12.754,15.565L8.569,2.315L8.569,2.315Z"
            fill={filled ? "url(#orangeYellowGradient)" : "#333333"}
        />
    </svg>
);

const StreakRewardsComponent = () => {
    const [dailyStreak, setDailyStreak] = useState(1);
    const [weeklyStreak, setWeeklyStreak] = useState(0);
    const [monthlyStreak, setMonthlyStreak] = useState(0);
    const [coins, setCoins] = useState(0)

    useEffect(() => {
        document.title = "Rewards | Ding";
    }, [])

    useEffect(() => {
        const fetchStreaks = async () => {
            try {
                const response = await getStreaksInfo();
                setDailyStreak(response.data.data.daily_streak || 0);
                setMonthlyStreak(response.data.data.monthly_streak || 0);
                setWeeklyStreak(response.data.data.weekly_streak || 0);
                setCoins(response.data.data.streak_coins || 0)
            } catch (error) {
                console.error('Failed to fetch streak info:', error);
            }
        };

        fetchStreaks();
    }, []);

    const progressPercentage = (dailyStreak / 30) * 100;

    return (
        <div className="streak-rewards">
            <div className="daily-streak">
                <div className="streak-header">
                    <span className="streak-title">Daily <img src={fireIcon} alt="Fire icon" /></span>
                    <div>
                        {[...Array(7)].map((_, index) => (
                            <StreakCounterIcon key={index} filled={index < dailyStreak} />
                        ))}
                    </div>
                    <span className="streak-days">7 days streak</span>
                </div>
                <p className="reward-message">Get a reward of 3000 coins upon completion</p>
            </div>

            <div className="monthly-streak">
                <div className="streak-header">
                    <span className="streak-title">Monthly <img src={fireIcon} alt="Fire icon" /> </span>
                    <div>
                        {[...Array(4)].map((_, index) => (
                            <StreakCounterIcon key={index} filled={index < weeklyStreak} />
                        ))}
                    </div>
                    <span className="streak-days">4 weeks streak</span>
                </div>
            </div>

            <div className="chest-rewards">
                <div className="progress-bar">
                    <div className="progress" style={{ width: `${progressPercentage}%` }}></div>
                </div>
                <div className="chests">
                    {[...Array(4)].map((_, index) => (
                        <div key={index} className={`chest ${index % 2 === 0 ? 'above' : 'below'}`}>
                            {index % 2 === 0 && (
                                <img 
                                    src={index < weeklyStreak ? chestOpen : chestIcon} 
                                    alt={index < weeklyStreak ? "Open chest" : "Closed chest"} 
                                />
                            )}
                            <span className="coin-amount">{index % 2 === 0 ? <><br />250 coins</> : <>250 coins<br /></>}</span>
                            {index % 2 !== 0 && (
                                <img 
                                    src={index < weeklyStreak ? chestOpen : chestIcon} 
                                    alt={index < weeklyStreak ? "Open chest" : "Closed chest"} 
                                />
                            )}
                        </div>
                    ))}
                </div>
            </div>
            <br />
            <DailyLogin coins={coins} />
            <br />
            <WeeklyChallenge />
            <br />
            <MonthlyChallenge />
            <br />
            <ShareAndGet />
            <br />
            <SpinAndWin />
            <br /> <br /> <br />
        </div>
    );
};

export default StreakRewardsComponent;