import React, { useLayoutEffect, useEffect, useState, useRef } from 'react';
import CoinsBlast from "../Animations/CoinsBlast";
import { getRewards } from "../../ApiCalls/Rewards/api_v1_rewards";
import { useNavigate, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";
import ding_token from '../../images/v1/home/ding_token.png';
import styles from '../../styles/rewards/rewards.module.css';
import rewardsTrack from '../../audio/rewards_sound.aac';
function Rewards() {
    const location = useLocation();
    let earnedMessage = useRef("");
    let coinsValue = useRef("");
    let scoreValue = useRef("");
    let totalValue = useRef("")
    let [show, setShow] = useState({
        show1: 0,
        show2: 0
    });
    let navigate = useNavigate();
    const rewardsTrackPlaying = useRef(false);
    const [uuid, setUUID] = useState({
        questionUUID: "",
        id: ""
    });
    const backToVideos = useRef(false);
    useLayoutEffect(() => {
        // document.body.style.backgroundColor = "#f8b600";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#f8b600");


    }, []);
    useEffect(() => {
        var track2 = new Audio(rewardsTrack)
        touchstart(track2);

        return () => {
            // second
            track2.pause();
        }
    }, [])

    const touchstart = (track2) => {

        if (rewardsTrackPlaying.current == false) {
            rewardsTrackPlaying.current = true;
            track2.loop = true;
            track2.volume = 0.05;
            track2.play();

        }
    };
    // u
    useEffect(() => {
        // console.log(location.state);
        if (location.state) {
            if (location.state.backToVideos) {
                backToVideos.current = true;
            }
            let videoId = location.state.questionUUID;
            let id = location.state.id;
            setUUID({ ...uuid, questionUUID: videoId, id: id });
            let rewardData = {
                "videoId": videoId,
            }
            getRewards(rewardData).then(response => {
                const accessToken = response.headers['access_token'];
                if (accessToken != null) {
                    localStorage.setItem('token', accessToken);
                }

                // const valuesArray = JSON.parse(response.data);
                earnedMessage.current.innerHTML = response.data.data.message;
                coinsValue.current.innerHTML = (response.data.data.coins == 0) ? "0" : response.data.data.coins + "00";
                scoreValue.current.innerHTML = response.data.data.score;
                totalValue.current.innerHTML = (backToVideos.current ? 3 : response.data.data.total);
                setTimeout(() => {
                    setShow({ ...show, show1: 1 })
                    setTimeout(() => {
                        setShow({ ...show, show1: 1, show2: 1 })
                    }, 700);
                }, 700);
                // response.data.message.forEach(element => {
                // console.log(element);
                // questionData.push({"choice":element.vid,"src":"/videos/"+element.video+".mp4#t=0.1","type":"video/mp4"});
                // setVideoLists([...videoList,videoList]);
                // })
                // console.log(videoList);
            })
                .catch(error => {
                    // console.log(error);
                    if (error.status === 404) {
                        earnedMessage.current.innerHTML = error.data.data.message;
                        coinsValue.current.innerHTML = 0;
                    }
                })

        } else {
            // navigate('/home')
        }
    }, [])
    function goToNextLevel() {
        let attempts = location.state.returnAttempt;
        navigate('/trivia?id=' + uuid.id, { state: { attemptNumber: attempts + 1 } })
    }
    function goToHome() {
        navigate('/home')
    }

    return (
        <>
            <main className={styles.mainSection}>
                <section className={styles.topSection}>
                    <h1 className={`${styles.topSectionH1} ${styles.topSectionH1} ${styles.zoomIn}`}>great work!</h1>
                    <p className={styles.topSectionP} ref={earnedMessage}></p>
                </section>
                <section className={styles.midSection}>
                    <img src={ding_token} alt="" className={styles.rewardsToken} />
                    <CoinsBlast />
                    <div className={(show.show1 == 1 ? styles.slideInDown : styles.dispNone)} >
                        <h1 className={styles.rewardValue} ref={coinsValue}></h1>
                        <h3 className={styles.rewardValueText}>coins</h3>
                    </div>
                    <p className={(show.show2 == 1 ? styles.score : styles.dispNone2)}>your score - <span ref={scoreValue}></span>/<span ref={totalValue}></span></p>
                </section>
                <section className={styles.buttonsSection}>
                    {backToVideos.current ?
                        <button type="button" className={styles.backBtn} onClick={goToNextLevel}>Go to next level</button>
                        : null}
                    <button type="button" className={styles.backBtn} onClick={goToHome}>Back to videos</button>
                </section>



            </main>
        </>
    )

}

export default React.memo(Rewards)