import React from 'react';

import spinChallenge from '../../../images/v1/rewards/spinChallenge.svg'
import { useNavigate } from 'react-router-dom';

const SpinAndWin = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/spin-wheel');
  };


  return (
    <div className="spin-and-win" onClick={handleClick}>
      <div className="blur blur-1"></div>
      <div className="blur blur-2"></div>
      <div className="blur blur-3"></div>
      <div className="content">
        <div className="text">
          <h2 className='stroke-color-black'>Spin & Win</h2>
          <p className='stroke-color-black'>Win up to 500 coins by spinning the wheel</p>
          <button className="play-now">Play Now</button>
        </div>
        <div className="image">
          <img src={spinChallenge} alt="Spin wheel" />
        </div>
      </div>
      <style jsx>{`
        .spin-and-win {
          background-color: #A5FFFB;
          border-radius: 15px;
          padding: 20px;
          position: relative;
          overflow: hidden;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .play-now {
          background-color: #008080;
          color: white;
          border: none;
          padding: 10px 20px;
          border-radius: 20px;
          font-weight: bold;
          cursor: pointer;
        }

      `}</style>
    </div>
  );
};

export default SpinAndWin;