import axiosClient from "../axiosClient";

export function getUser() {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get("/user");
}

export function setInterests(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post("/user-interests", JSON.stringify(data));
}

export function editUser() {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get("/edit-user");
}

export function saveUser(data) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post("/save-user", JSON.stringify(data));
}

export function saveAvatar(avatarSettings) {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.post("/emoji-avatar/save", JSON.stringify(avatarSettings));
}

export function loadAvatar() {
    let bearerTokenHeader = localStorage.getItem('token');
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
    return axiosClient.get("/emoji-avatar/load");
}

// export function getUserInfo(data){
//     let bearerTokenHeader = localStorage.getItem('token');
//     console.log(bearerTokenHeader);
//     axiosClient.defaults.headers.common['Authorization'] = `Bearer ${bearerTokenHeader}`;
//     return axiosClient.post('/like',JSON.stringify(data));
// }