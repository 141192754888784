import React, { useEffect, useState } from 'react';
import styles from '../../styles/home/sidebarBottom.module.css';
import '../../styles/home/svg_styles.css';
import { Link } from 'react-router-dom';
import { likeDislike } from "../../ApiCalls/Home/api_v1_home";
import searchImg from "../../images/v1/icons/search.png";
import shareImg from "../../images/v1/icons/share.svg";

function SidebarBottom({ vid, like, likeDislikeHandler, showShareSection, showSearchSection }) {
    // const [heartRed, setHeartRed] = useState(like);
    // useEffect(() => {
    //     setHeartRed(like);
    // }, [like])

    // let varLikeVideo = likeVideo
    // console.log(vid);
    function handleLike() {
        likeDislikeHandler(like === 1 ? 0 : 1);
        // setHeartRed(like);
        let likeData = {
            "vid": vid,
        }
        likeDislike(likeData).then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            // likeDislikeHandler(response.data.data.videoLikes);
            // setHeartRed(response.data.data.videoLikes);
            // varLikeVideo=  response.data.data.videoLikes
        })
            .catch(error => {
                // .response.status,error.response.data.message
                // console.log(error.response.status,error.response.data.message);
            })
    }

    return (
        <>
            <div className={styles.sidebarBottomContainer}>
                <div className={styles.sidebarBtnSearch}>
                    <Link role="button" onClick={() => showSearchSection()} className={styles.sidebarLink}>
                        <div className={styles.sidebarLinkBg}>
                            <img src={searchImg} alt="..." style={{ width: "18px" }} className={styles.sidebarLinkImg} />
                        </div>
                        <p className={styles.sidebarLinkText}>Search</p>
                    </Link>
                </div>
                <div className={styles.sidebarBtnHeart}>
                    <Link role="button" onClick={handleLike} className={styles.sidebarLink}>
                        <div className={styles.sidebarLinkBg}>
                            {like ?
                                <svg className={styles.svgSize} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.62 53.47"><defs></defs><g id="Artboard_1" data-name="Artboard 1" className="cls-111"><g id="share_icon_Image" data-name="share icon Image"><path className="cls-333" d="M32.31,51.69,23.48,46a104.45,104.45,0,0,1-8.78-6.45C8.55,34.47,1.5,27.43,1.5,17.77a16.27,16.27,0,0,1,30.81-7.31,16.27,16.27,0,0,1,30.81,7.31c0,9.66-7,16.7-13.2,21.76A103.32,103.32,0,0,1,41.15,46Z" /><path className="cls-444" d="M32.31,51.69,23.48,46a104.45,104.45,0,0,1-8.78-6.45C8.55,34.47,1.5,27.43,1.5,17.77a16.27,16.27,0,0,1,30.81-7.31,16.27,16.27,0,0,1,30.81,7.31c0,9.66-7,16.7-13.2,21.76A103.32,103.32,0,0,1,41.15,46Z" /></g></g></svg>
                                :
                                <svg className={styles.svgSize} width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M1.82347 9.12312C3.22547 13.4851 8.76447 17.0121 10.2365 17.8851C11.7135 17.0031 17.2925 13.4371 18.6495 9.12712C19.5405 6.34112 18.7135 2.81212 15.4275 1.75312C13.8355 1.24212 11.9785 1.55312 10.6965 2.54512C10.4285 2.75112 10.0565 2.75512 9.78647 2.55112C8.42847 1.53012 6.65447 1.23112 5.03747 1.75312C1.75647 2.81112 0.932468 6.34012 1.82347 9.12312ZM10.2375 19.5011C10.1135 19.5011 9.99047 19.4711 9.87847 19.4101C9.56547 19.2391 2.19247 15.1751 0.395468 9.58112C0.394468 9.58112 0.394468 9.58012 0.394468 9.58012C-0.733532 6.05812 0.522469 1.63212 4.57747 0.325118C6.48147 -0.290882 8.55647 -0.019882 10.2345 1.03912C11.8605 0.0111181 14.0205 -0.272882 15.8865 0.325118C19.9455 1.63412 21.2055 6.05912 20.0785 9.58012C18.3395 15.1101 10.9125 19.2351 10.5975 19.4081C10.4855 19.4701 10.3615 19.5011 10.2375 19.5011Z" fill="white" />
                                </svg>}
                        </div>
                        {like ? <p className={styles.sidebarLinkText}>Saved</p> : <p className={styles.sidebarLinkText}>Save</p>}
                    </Link>
                </div>
                <div className={styles.sidebarBtnShare}>
                    <Link role="button" onClick={() => showShareSection()} className={styles.sidebarLink}>
                        {/* <svg className={styles.svgSize} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64.91 57.96"><defs></defs><g id="Artboard_1" data-name="Artboard 1" className="cls-1"><g id="share_icon_Image" data-name="share icon Image"><path className="cls-3" d="M34.43,34,34.85,46A.73.73,0,0,0,36,46.6l27.1-21.91a.73.73,0,0,0,0-1.13L36,1.66a.73.73,0,0,0-1.19.56l-.54,10c-5.43,1-10,2.42-14.45,5.21A40.38,40.38,0,0,0,8.14,28.55,37.55,37.55,0,0,0,2.2,42.44a34.81,34.81,0,0,0,0,14h.68A31.17,31.17,0,0,1,8,44.86,28.5,28.5,0,0,1,16.61,37a25.37,25.37,0,0,1,10-3.35A45.4,45.4,0,0,1,34.43,34Z" /></g></g></svg> */}
                        <div className={styles.sidebarLinkBg}>
                            <img src={shareImg} alt="..." style={{ width: "18px" }} />
                        </div>
                        <p className={styles.sidebarLinkText}>Share</p>
                    </Link>
                </div>
            </div>
        </>
    );
}

// export default React.memo(SidebarBottom);
export default SidebarBottom;