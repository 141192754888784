import React, { useState } from 'react';

const AvatarChanges = () => {
 
  return (
    <div className="faq-page">
      <h1>Early Avatar Changes</h1>
      <div className="faq-list">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam culpa commodi et nostrum enim tempore repellat id neque temporibus possimus nobis minima nihil voluptatum obcaecati exercitationem quisquam, sequi, fugiat eaque!
      </div>

      <style jsx>{`
        .faq-page {
          background-color: #1a1a1a;
          color: #ffffff;
          min-height: 100vh;
          padding: 20px;
          font-family: Arial, sans-serif;
        }

        h1 {
          text-align: center;
          margin-bottom: 20px;
        }

        .faq-list {
          max-width: 800px;
          margin: 0 auto;
        }

        .faq-item {
          margin-bottom: 10px;
        }

        .faq-question {
          padding: 15px;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: bold;
          background: #252525;
          border-radius: 8px;
        }

        .arrow {
          transition: transform 0.3s ease;
        }

        .arrow.open {
          transform: rotate(180deg);
        }

        .faq-answer {
          padding: 15px;
          background-color: #252525;
          line-height: 1.6;
          border-radius: 8px;
        }
      `}</style>
    </div>
  );
};

export default AvatarChanges;